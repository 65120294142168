import React, { useState } from 'react'
import Layout from '../components/layout'
import { Container, Row, Col, Form, FormGroup, Input, ModalBody, Modal } from 'reactstrap'
import styled from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade';
import { TiSocialLinkedin } from 'react-icons/ti';
import User01 from '../images/consultants/01.png'
import User1 from '../images/consultants/1.png'
import User2 from '../images/consultants/2.png'
import User3 from '../images/consultants/3.png'
import User4 from '../images/consultants/4.png'
import User7 from '../images/consultants/7.png'
import User8 from '../images/consultants/8.png'
import Video from '../video/consultants.mp4'
import BlogForm from './Company/Blog/BlogForm'

const Consultants = (props) => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <StaticQuery
            query={graphql`
      query {
        Banner: file(relativePath: { eq: "consultants.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1220, maxHeight:400,) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          } 
        
      }
    `}

            render={data => {
                const imageData = data.Banner.childImageSharp.fluid
                const {
                    // buttonLabel,
                    className
                } = props;
                return (
                    <Layout path="/consultants/">
                        <section className="banner_min">
                            <div className="banner_section">
                                <Img
                                    fluid={imageData}
                                    className="startup_background"
                                ></Img>
                            </div>
                            <div className="banner_blur4">
                                <div className="first_banner">
                                    <Container>
                                        <Row>
                                            <Col sm="12" md={{ size: 7, offset: 6 }}>
                                                <div>
                                                    <Fade right> <h1>Trident works to win your trust</h1></Fade>
                                                </div>
                                                {/* <div className="first_text">
                                                <Fade right>
                                                    <h1>Yet another faceless staffing agency?</h1>
                                                </Fade>
                                                <Fade right delay={1000}>
                                                    <h1>Collect your resume and go silent?</h1>
                                                </Fade>
                                                <Fade right delay={2000}>
                                                    <h1>Negotiate down your fair value?</h1>
                                                </Fade>
                                                <Fade right delay={3000}>
                                                    <h2>Not here.</h2>
                                                </Fade>
                                            </div> */}
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </section>
                        <section className="cn_bg">
                            <Container>
                                <Row>
                                    <Col md="6">
                                        {/* <div className="consultants_border"> */}
                                        <div className="consultants_text">
                                            {/* <h3>Trident Works to win your trust</h3> */}
                                            <h4>Yet another faceless staffing agency?</h4>
                                            <h4>Collect your resume and go silent?</h4>
                                            <h4>Negotiate down your fair value?</h4>
                                            <br />
                                            <h4 style={{ color: '#1b98ce' }} >"Not here"</h4>

                                            {/* </div> */}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <ul>
                                            <li>When you succeed, we shine. In the end, a staffing agency is worth only the consultants who keep us in business.</li>
                                            <li>Our clients appreciate the fact that we have helped them retain talent for long stints, and in many cases, our consultants have gone back to clients a second or third time.</li>
                                            <li>We believe being transparent is the easiest business ethic to practice—we don’t have to remember what we said, and we stand by what we say.</li>
                                            <li>As a minority-owned business, Trident actively practices diversity in the workplace, and has gained reputation with large business in delivering niche talent fulfillment.</li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        <section className="section_margin">
                            <Container>
                                <div className="text_center">
                                    <h3>What our consultants say</h3>
                                </div>
                                <Row>
                                    <Col sm="12" md={{ size: 8, offset: 2 }}>
                                        <div className="consultants_video">
                                            <div className="video">
                                                <video object-fit="cover" controls>
                                                    <source src={Video} type="video/mp4"  ></source>
                                                </video>
                                            </div>
                                            <div className="text">
                                                <h3> Listen to what our consultant, Fred Bikash has to say about us</h3>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4">
                                        <div className="consultants_user">
                                            <div className="consultants_img">
                                                <a href="https://www.linkedin.com/in/arpit-jain-ab13a7130/" target="blank">
                                                    <img src={User1} alt="img" />
                                                    <div className="overlay" />
                                                    <div className="social">
                                                        <TiSocialLinkedin color={'#fff'} size={35} />
                                                    </div>
                                                </a>
                                            </div>
                                            <h5>Arpit Jain</h5>
                                            <p>I recently started my contract role with Trident and till now it has been a great experience. I have been working with Sridhar and Neetu and they have helped me in almost every possible way.</p>
                                            {/* <p>I have recently started my contract role with Trident and till now it has been great experience. I have been working with Sridhar and Neetu and they have helped me in almost every possible way.</p> */}
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="consultants_user">
                                            <div className="consultants_img">
                                                <a href="https://www.linkedin.com/in/brent-boswell-24765718/" target="blank">
                                                    <img src={User2} alt="img" />
                                                    <div className="overlay" />
                                                    <div className="social">
                                                        <TiSocialLinkedin color={'#fff'} size={35} />
                                                    </div>
                                                </a>
                                            </div>
                                            <h5>Brent Boswell</h5>
                                            <p>Trident came through when I needed them most! They landed me a contract at Intel, at my requested rate, in a related field, after I was laid off from my network administration position that I held for the last 11 years. Sridhar Ramalingam, my recruiter at Trident, got me started working again under a week from the time that Intel selected me from the position-almost unheard of.</p>
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="consultants_user">
                                            <div className="consultants_img">
                                                <a href="https://www.linkedin.com/in/niranjana-sivakumar-18a66538" target="blank">
                                                    <img src={User3} alt="img" />
                                                    <div className="overlay" />
                                                    <div className="social">
                                                        <TiSocialLinkedin color={'#fff'} size={35} />
                                                    </div>
                                                </a>
                                            </div>
                                            <h5>Niranjana Shivkumar</h5>
                                            <p>I have been working with Trident Consulting for the last couple of years now. Trident is a good company to work with. Some of the things that I particularly like about them: Very prompt in their payments to employees; an open communication channel with the management; and they maintain a cordial relationship with clients.</p>
                                            {/* <p>I have been working with Trident Consulting for the last couple of years now.
                                            Trident Consulting is a good company to work with.
                                            Some of the things that I particularly like about them :
                                            Very prompt in their payments to employees
                                            Open communication channel with the management
                                            They maintain a cordial relationship with the clients
                                         </p> */}
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="consultants_user">
                                            <div className="consultants_img">
                                                <a href="https://www.linkedin.com/in/suganthi-subramanian-csm-3750013/" target="blank">
                                                    <img src={User4} alt="img" />
                                                    <div className="overlay" />
                                                    <div className="social">
                                                        <TiSocialLinkedin color={'#fff'} size={35} />
                                                    </div>
                                                </a>
                                            </div>
                                            <h5> Suganthi Subramanian</h5>
                                            <p>In my experience with Trident, they have stood apart from the several other consulting companies that work to match-up talent with placements.  I have worked with Mohan and  Sridhar.  They have all been very respectful and goal oriented, and more importantly, people oriented.  I wish them much success.</p>
                                            {/* <p>Testimony: In my experience with Trident; they stood apart from the several other consulting companies that work to match-up talent with placements.  I have worked with Mohan, Sridhar & Sridhar's boss.  They have all been very respectful and goal oriented & also more importantly people oriented.  I wish them much success.</p> */}
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="consultants_user">
                                            <div className="consultants_img">
                                                <a href >
                                                    <img src={User7} alt="img" />
                                                    <div className="overlay" />
                                                    <div className="social">
                                                        <TiSocialLinkedin color={'#fff'} size={35} />
                                                    </div>
                                                </a>
                                            </div>
                                            <h5>Venkata Siva Saitej Krothapalli</h5>
                                            <p>Trident is a good place to learn IT and Business. Lots of opportunities and nice work-life balance They maintain a good and prompt payment procedure.The support from management and the workplace culture is good, few projects below average, but that happens in all the companies. I was mentored by best people in that discipline.</p>
                                            {/* <p>Good place to learn IT and Business.
                                            Lots of opportunities/Roles to play
                                            Nice work life balance
                                            Good and prompt payment procedure
                                            Support from management
                                            Workplace culture is overall good, few projects below average, but that happens in all the companies, was mentored by best people in that discipline.
                                         </p> */}
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="consultants_user">
                                            <div className="consultants_img">
                                                <a href >
                                                    <img src={User01} alt="img" />
                                                    <div className="overlay" />
                                                    <div className="social">
                                                        <TiSocialLinkedin color={'#fff'} size={35} />
                                                    </div>
                                                </a>
                                            </div>
                                            <h5>Chetan Shetty</h5>
                                            <p>I joined the company 2 months ago and felt welcome immediately. The on-boarding process was smooth and transparent, and the team was very flexible and accommodating.</p>
                                            {/* <p>I have joined the company 2 month back and was felt welcome immediately. The on-boarding process was smooth and transparent, and the team was very flexible and accommodating.</p> */}
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="consultants_user">
                                            <div className="consultants_img">
                                                <a href>
                                                    <img src={User01} alt="img" />
                                                    <div className="overlay" />
                                                    <div className="social">
                                                        <TiSocialLinkedin color={'#fff'} size={35} />
                                                    </div>
                                                </a>
                                            </div>
                                            <h5>Nick Zamiatin</h5>
                                            <p>He is a passionate full stack developer with specialized training and education in JavaScript and JavaScript frameworks. He is working with Cognizant Technologies for Merck.</p>
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="consultants_user">
                                            <div className="consultants_img">
                                                <a href="https://www.linkedin.com/in/aditya-pullapantula-814baa69/" target="blank">
                                                    <img src={User8} alt="img" />
                                                    <div className="overlay" />
                                                    <div className="social">
                                                        <TiSocialLinkedin color={'#fff'} size={35} />
                                                    </div>
                                                </a>
                                            </div>
                                            <h5>Aditya Kashyap </h5>
                                            <p>I started my role with Trident 10 months ago and until now it has been a great experience. I was placed as a BA with Wells Fargo, CA in a short span of time and the role is fabulous. The project was amazing. But since my family was in the east coast I had to move back. Currently I work for Verizon as Senior BA in Piscataway, NJ. I have been working with Osborn, Shabana and Neetu and they have helped me in almost every possible way. Whenever I needed them all I had to do was make a call and they would be available to answer. I recommend Trident for its positive energy.</p>
                                            {/* <p>I have started my role with Trident 10 months back and till now it has been great experience.
                                           I was placed as a BA with Wells Fargo in, CA in short span of time and the role was fabulous.
                                           The project was amazing. But since my family was in east coast I had to move back. Currently I am working for Verizon as Senior BA in Piscataway NJ.
                                           I have been working with Osborn, Shabana and Neetu and they have helped me in almost every possible way. Whenever you needed them all I had to do was make a call and they would be available to answer. Recommend Trident for its positive energy
                                        </p> */}
                                        </div>
                                    </Col>

                                </Row>
                            </Container>
                        </section>
                        <section>
                            <div className="startup_bg">
                                <div className="startup_section">
                                    <Container>
                                        <Row>
                                            <div className="card">
                                                <Col md="12">
                                                    <div>
                                                        <div className="text_heading">
                                                            <h3>Refer a Friend.</h3>
                                                            <h4>It pays to have friends</h4>
                                                            <div className="form_text">
                                                                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p> */}
                                                            </div>
                                                            {/* <br />
                                                        <br /> */}
                                                        </div>
                                                        <Form>
                                                            <div>
                                                                <h3 className="form_heading">STEP 1 - Friend's Information</h3>
                                                            </div>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="text" name="name" placeholder="Friend's First Name*" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="text" name="name" placeholder="Friend's Last Name" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="email" name="email" placeholder="Email Address" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="number" name="number" placeholder="Mobile Number" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="text" name="HomeNumber" placeholder="Home Number" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="text" name="profile" placeholder="LinkedIn Profile Page" />
                                                                    </FormGroup>
                                                                </Col>
                                                                {/* <Col md={6}>
                                                                <FormGroup>
                                                                    <CustomInput bsSize="md" type="select" id="exampleCustomSelect">
                                                                        <option value="">Select Participating Trident Office*</option>
                                                                        <option>Value 1</option>
                                                                        <option>Value 2</option>
                                                                        <option>Value 3</option>
                                                                        <option>Value 4</option>
                                                                        <option>Value 5</option>
                                                                    </CustomInput>
                                                                </FormGroup>
                                                            </Col> */}
                                                            </Row>
                                                            <div className="form_text">
                                                                <h3 className="form_heading">STEP 2 - Your Information</h3>
                                                            </div>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="text" name="FirstName" placeholder="Your First Name*" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="text" name="LastName" placeholder="Your Last Name" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="email" name="Email" placeholder="Your Email Address*" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="number" name="number" placeholder="Your Mobile Number" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="text" name="HomeNumber" placeholder="Your Home Number" />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            {/* <p>Referral program not available in all locations. Check with your local Trident office for details.</p> */}
                                                            <button>Submit</button>
                                                        </Form>
                                                    </div>
                                                </Col>
                                            </div>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </section>
                        <section className="footer_section">
                            <Container>
                                <Row>
                                    <Col md="12">
                                        <h1>Find your next role with Trident </h1>
                                        <div className="footer_button">
                                            <a href onClick={toggle}>Contact Us</a>
                                        </div>
                                        <Modal isOpen={modal} toggle={toggle} className={className} className="form_modal" >
                                            <ModalBody >
                                                <BlogForm title="Find your next role with Trident"  close={()=> toggle()}/>
                                            </ModalBody>

                                        </Modal>
                                    </Col>
                                </Row>
                            </Container>
                        </section>

                        {/* <BlogForm refItem={() => { }} title="Find your next role with Trident." redirectTitle="Consultants" pathname={props.location.pathname} /> */}
                    </Layout>
                )
            }}
        />
    )
}
const StyledBackgroundSection = styled(Consultants)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  margin: 0;
  background-color: inherit !important;
  
  `

export default StyledBackgroundSection